import * as React from "react"
import { Layout, TwoColumnContainer, SEO } from "../components"
import { withHandlers, compose, withState } from "recompose";
import styled from "styled-components";
import { DarkButton } from "../components/buttons";
import { sendEmail } from "../clients/hcon-api";
import { FaCircleNotch } from "react-icons/fa";
import home2 from "../images/home2.jpg";


const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 0.75rem;
`;

const StyledTwoColumnContainer = styled(TwoColumnContainer)`
  min-height: 100vh;
`;

const Title = styled.h1`
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    margin-top: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-bottom: 2rem;
  > button {
    margin-right: 0.5rem;
  }
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    > button {
      margin-bottom: 1rem;
    }
  }

  
`;

const Text = styled.div`
  margin-bottom: 2.5rem;
`;

const ClearButton = styled(DarkButton)`
  background-color: transparent;
  border: 2px solid ${props => props.theme.textGrey};
  color: ${props => props.theme.textGrey};
`;

const Content = styled.div`
  max-width: 35rem;
`;

const Error = styled.div`
  color: red;
  margin-bottom: 1rem;
`;

const content = {
  seo: {
  siteUrl: 'https://hconcoinc.com/estimate',
  title: "Estimate",
  keywords: [`concrete estimate`, `free construction estimate`, `concrete`, `hconco`, `nashville`],
  description: 'Get a free estimate for any of our concrete services in Nashville, TN such as concrete paving, masonry brick and block, foundations, and tilt up construction'
  }
}

const EstimatePage = ({ formState, handleInputChange, handleSubmit, handleReset, submitSuccess, error, submitInProgress }) => (
  <Layout>
    <SEO title={content.seo.title} keywords={content.seo.keywords} description={content.seo.description} siteUrl={content.seo.siteUrl}/>
    <StyledTwoColumnContainer background={home2}>
      <Title>Request a Free Estimate</Title>
      <Text>Contact us for a free estimate or for general inquires.</Text>
      <Content>
        {error && <Error>{error}</Error>}

        {!submitSuccess && <form onSubmit={handleSubmit} onReset={handleReset}>
          <FlexColumn>
            <FlexColumn>
              <label htmlFor="name">* Name</label>
              <Input
                id="name"
                name="name"
                type="text"
                value={formState.name}
                onChange={handleInputChange}
                autoComplete="name"
                required
                disabled={submitInProgress}
              />
            </FlexColumn>

            <FlexColumn>
              <label htmlFor="phone">* Phone</label>
              <Input
                id="phone"
                name="phone"
                type="tel"
                value={formState.phone}
                onChange={handleInputChange}
                autoComplete="tel"
                required
                disabled={submitInProgress}
              />
            </FlexColumn>

            <FlexColumn>
              <label htmlFor="email">* Email</label>
              <Input
                id="email"
                name="email"
                type="email"
                value={formState.email}
                onChange={handleInputChange}
                autoComplete="email"
                required
                disabled={submitInProgress}
              />
            </FlexColumn>

            <FlexColumn>
              <label htmlFor="address">* Site/Home Address</label>
              <Input
                id="address"
                name="address"
                value={formState.address}
                onChange={handleInputChange}
                autoComplete="shipping street-address"
                disabled={submitInProgress}
                required
              />
            </FlexColumn>

            <FlexColumn>
              <label htmlFor="message">Message</label>
              <textarea 
                id="message" name="message" value={formState.message} onChange={handleInputChange} rows={10} disabled={submitInProgress} />
            </FlexColumn>

            <ButtonContainer>
              <DarkButton type="submit" value="Submit" disabled={submitInProgress}>
                {!submitInProgress && <span>Submit</span>}
                {submitInProgress && <span><FaCircleNotch className="fa-spin"/></span>}
              </DarkButton>
              <ClearButton type="reset" value="Reset" disabled={submitInProgress}>Reset</ClearButton>
            </ButtonContainer>


          </FlexColumn>

        </form>

        }
        {submitSuccess && <div><h3>Request Sent!<br/></h3> We will get back to you as soon as we can. </div>}
      </Content>

    </StyledTwoColumnContainer>
  </Layout>
)

const defaultFormState = { name: '', phone: '', address: '', email: '', message: '' }

const withEstimateBehaviors: any = compose<any, any>(
  withState('formState', 'setFormState', defaultFormState),
  withState('error', 'setError', null),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('submitInProgress', 'setSubmitInProgress', false),
  withHandlers({
    handleInputChange: ({ formState, setFormState }: any) => (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      setFormState({
        ...formState,
        [name]: value
      });
    },
    handleSubmit: ({ formState, setError, setSubmitSuccess, setSubmitInProgress }) => async (event) => {
      event.preventDefault();
      setError(null);
      setSubmitSuccess(false);
      const data = {
        name: formState.name || '',
        email: formState.email || '',
        phone: formState.phone || '',
        address: formState.address || '',
        message: formState.message || '',
        date: new Date().toLocaleDateString()
      };

      try {
        setSubmitInProgress(true);
        await sendEmail('estimate', data);
        setSubmitSuccess(true);
      } catch (error) {
        setError('An error occurred while submitting the form. Please try again.');
      } finally {
        setSubmitInProgress(false);
      }
    },
    handleReset: ({ setFormState, setError }) => (event) => {
      event.preventDefault();
      setFormState(defaultFormState);
      setError(null);
    }
  })
)

export default withEstimateBehaviors(EstimatePage)
