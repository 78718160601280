const baseUrl = `${process.env.HCON_API_URL}`;

export const sendEmail = async (type: EmailType, data: any) => {
  const params = {
    type,
    data
  }
  const body = JSON.stringify(params);
  const response = await fetch(
    `${baseUrl}/email/send`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body,
    }
  ).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  });

  return response;
}

export type EmailType = 'estimate';